
const themeConfig = {
  app: {
    appName: 'Admin',
    appLogoImage: require(`@src/assets/images/logo/gasbiker_dark.png`).default
  },
  layout: {
    isRTL: false,
    skin: 'light', 
    routerTransition: 'fadeIn', 
    type: 'vertical', 
    contentWidth: 'boxed',
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      type: 'floating', 
      backgroundColor: 'white' 
    },
    customizer: false,
    scrollTop: true 
  }
}

export default themeConfig
