import { createSlice } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

const config = useJwt.jwtConfig


export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    user: null,
    inscriptionStates: {}
  },
  reducers: {
    loginUser: (state, action) => {
      state.user = action.payload;
    },
    logout: state => {
      state.user = null;
    },
    tokenOut: (state, action) => {
      state.user.token = action.payload;
    },
    setInscriptionStates: (state, action) => {
      state.inscriptionStates = action.payload;
    }
  }
})

export const { loginUser, logout, changeName, tokenOut, setInscriptionStates } = authSlice.actions

export default authSlice.reducer
