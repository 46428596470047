// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

/**
 * el slice almacena los reducers 
 */
export const routingSlice = createSlice({
  name: 'routing',
  initialState: {
    redirectTo: null,
  },
  reducers: {
    redirect: (state, action) => {
      state.redirectTo = action.payload; //pone  stado de la store 
    },
  }
})

export const { redirect } = routingSlice.actions

export default routingSlice.reducer
