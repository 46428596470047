// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

const config = useJwt.jwtConfig

/**
 * el slice almacena los reducers 
 */
export const alert = createSlice({
  name: 'alert',
  initialState: {
    show: false,
    content: {
      title: '',
      description: '',
      type: ''
    }
  },
  reducers: {
    showAlert: (state, action) => {
      state.show = true; 
      state.content.title = action.payload.title;
      state.content.description = action.payload.description;
      state.content.type = action.payload.type;
    },

    hideAlert: (state) => {
      state.show = false; 
      state.content.title = '';
      state.content.description = '';
      state.content.type = '';
    },
  }
})

export const { showAlert, hideAlert } = alert.actions

export default alert.reducer
